import { Col, Layout, Popover, Row } from 'antd'
import poweredByFac from '../../img/poweredByFac.png';
import visaSecure from '../../img/visaSecure.jpg';
import mcSecure from '../../img/mcSecure.png';
import amex from '../../img/amex.png';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

export const Footer: React.FC<any> = () => {

    return (
        <Layout.Footer style={{
            position: 'sticky',
            bottom:'0',
            width: '100%'
        }}> 
             <Row>
                <Col md={12} sm={12} xs={0}>
                   <span>Se muestra cuotas pendientes y del mes actual. </span>
                    <span>Contactanos al departamento de cobros por whatsapp: 9511-7392, 9511-7654 (8:00 a.m a 5:00 p.m) o al correo electrónico cobroscredilee@ladylee.com</span>
                    {" "}
                    <a href="/SAC_TerminosCondiciones_CrediLee.pdf" target="_blank">Terminos y Condiciones</a>
                </Col>
                <Col md={12} sm={12} xs={0} style={{ textAlign:'end' }}>
                    <img src={poweredByFac} style={{width: '100px', marginRight: '10px' }}/>
                    <img src={visaSecure} style={{width: '50px', marginRight: '10px' }}/>
                    <img src={mcSecure} style={{width: '100px', marginRight: '10px' }}/>
                    <img src={amex} style={{width: '50px', marginRight: '10px' }}/>
                </Col>
                <Col md={0} sm={0} xs={2} style={{ textAlign:'center' }}>
                    <Popover content={(
                        <>
                            <span>Contactanos al departamento de cobros por whatsapp: 9511-7392, 9511-7654 (8:00 a.m a 5:00 p.m) o al correo electrónico cobroscredilee@ladylee.com</span>
                            {" "}
                            <a href="/SAC_TerminosCondiciones_CrediLee.pdf" target="_blank">Terminos y Condiciones</a>                        
                        </>
                    )} title="" placement="top">
                        <InfoCircleOutlined />
                    </Popover>
                </Col>
                <Col md={0} sm={0} xs={22} style={{ textAlign:'center' }}>
                   

                    <img src={poweredByFac} style={{width: '100px', marginRight: '10px', marginBottom: '5px' }}/>
                    <img src={visaSecure} style={{width: '50px', marginRight: '10px' , marginBottom: '5px' }}/>
                    <img src={mcSecure} style={{width: '100px', marginRight: '10px' , marginBottom: '5px' }}/>
                    <img src={amex} style={{width: '50px', marginRight: '10px' , marginBottom: '5px' }}/>
                </Col>
            </Row>
        </Layout.Footer>
    )
}