import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CardPayment from '../pages/cardPayment/cardPayment';
import Login from '../pages/login/login';
import Payments from '../pages/payments/payments';
import PaymentDetails from '../pages/paymentDetails/paymentDetails'
import MaintenancePage from '../pages/maintenance/maintenance-page'
import { UserStateContainer } from './UserStateContainer';
// import SignIn from '../pages/SignIn';
// import SignUp from '../pages/SignUp';
// import Dashboard from '../pages/Dashboard';

function Routes() {
  return (
    <UserStateContainer.Provider>
      <BrowserRouter>
          <Switch>
              {/* <Route path="/" component={Login} /> */}
              <Route path="/login" component={Login} />
              <Route path="/payments" component={Payments} />
              <Route path="/card-payment" component={CardPayment} />
              <Route path="/payment-details" component={PaymentDetails} />
              {/*<Route path="/maintenance" component={MaintenancePage} />*/}
              {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
              <Route component={Login} />
              {/* redirect user to Maintenance Page inactive on 2022-11-01 */}
              {/*<Route component={MaintenancePage} />*/}
          </Switch>
      </BrowserRouter>
    </UserStateContainer.Provider>
  );
}

export default Routes;