// import React from 'react';
import Header from '../../components/header/header';
import { Affix, Button, Col, Layout, Row, Table, Typography } from 'antd';
import './payments.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UserStateContainer } from '../../container/UserStateContainer';
import { Link, useHistory } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import NumberFormat from 'react-number-format';
import { DateTime } from 'luxon';
import { Footer } from '../../components/footer/footer';

const { Text } = Typography;

export interface Payment {
  id: string;
  fecha: string,
  fechaOp: string,
  montoCuota: number,
  noCuota: number,
  noOp: number,
  saldoCuota: number
}

export interface User {
  id: String;
  name: String;
}


const columns: ColumnsType<any> = [
  {
    title: 'Fecha de Pago',
    dataIndex: 'fecha',
  },
  {
    title: 'Cuotas',
    dataIndex: 'noCuota',
    sorter: (a, b) => a.noCuota - b.noCuota
  },{
    title: 'Número OP',
    dataIndex: 'noOp',
    responsive: ['md'],
    onFilter: (value,record) => record.noOp === value, 
    sorter: (a, b) => a.noOp - b.noOp
  },{
    title: 'Saldo Cuota',
    dataIndex: 'saldoCuota',
    render:(value, row) => (
      <NumberFormat fixedDecimalScale decimalScale={2} value={value} displayType={'text'} thousandSeparator={true} prefix={'L. '} />
    )
  },{
    title: 'Monto Cuota',
    dataIndex: 'montoCuota',
    responsive: ['md'],
    render:(value, row) => (
      <NumberFormat fixedDecimalScale decimalScale={2} value={value} displayType={'text'} thousandSeparator={true} prefix={'L. '} />
    )
  }
];


function Payments() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState<any>();
  const [minPayments, setMinPayments] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const [payments, setPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const { setJwt, jwt, name, id, setName, setId } = UserStateContainer.useContainer();

  const getData = () => {
    setIsLoading(true);
    axios.get('https://api.ladyleehn.com/credilee/payment', {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }).then((response: any) => {
      let cuotas = [];
      if (response.data.success) {
        cuotas = response.data.payload['detalleCuotas'];
        // noOp
        columns[1].filters = response.data.payload.filters.noOp.map((value: number) => {
          return {
            text: value,
            value
          }
        })
      }
      setName(response.data.payload['nombreCliente']);
      setId(response.data.payload['identidad'])
      calculateMinCuotas(cuotas);
      setPayments(cuotas.sort((a:any, b:any) => {
        const aDate = DateTime.fromFormat(a.fecha, 'dd/MM/yyyy');
        const bDate = DateTime.fromFormat(b.fecha, 'dd/MM/yyyy');
        if (aDate < bDate) return -1;
        if (aDate > bDate) return 1;
        return 0;
      }));
      setIsLoading(false);
    }).catch((error) => {
      setJwt('');
    })
  }

  const calculateMinCuotas = (cuotas: any) => {
    let minValues: any = {

    }
    cuotas.forEach((cuota : any) => {
      if ( !minValues[cuota.noOp] ) {
        minValues[cuota.noOp] = cuota.noCuota
      } else if (minValues[cuota.noOp] > cuota.noCuota) {
        minValues[cuota.noOp] = cuota.noCuota
      }
    })
    setMinPayments(minValues);
  }

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    let tempTotal = 0;
    const orderSelectedRows = selectedRows.sort((a:any, b:any) => a.noOp == b.noOp ? a.noCuota - b.noCuota : a.noOp - b.noOp);
    orderSelectedRows.forEach((payment: any) => {
      tempTotal = tempTotal + (payment ? payment.saldoCuota : 0 );
    });
    setTotalAmount(tempTotal);
    updateMinValues(selectedRows);
    setSelectedRowKeys(orderSelectedRows.map((a:any) => a.id));
    setSelectedItems(orderSelectedRows);
  };

  const updateMinValues = (selectedRows: any) => {
    const checkValues = { ...minPayments }
    if (selectedRows.length === 0) {
      calculateMinCuotas(payments)
      return;
    }
    selectedRows.forEach((payment:any) => {
      if (checkValues[payment.noOp]) {
        checkValues[payment.noOp] = payment.noCuota + 1
      }
    })
    setMinPayments(checkValues)
  }

  const isValidSelect = (selectedRows: any) => {
    const checkValues = { ...minPayments }
    let isValid = true;
    selectedRows.forEach((payment:any) => {
      if (checkValues[payment.noOp] && checkValues[payment.noOp] === payment.noCuota) {
        checkValues[payment.noOp]++;
      } else {
        isValid = false;
      }
    })
    return isValid;
  }

  useEffect(() => {
    if (jwt) {
      getData();
    } else {
      history.replace('/login');
    }
  }, [jwt]);

  return (
    <Layout>
      <Header title="Pagos pendientes" />
      <Layout.Content style={{ minHeight:'calc(101vh - 100px)' }}>
        <div className="table-container">
          <div className="user-data">
            <div>
              <b className="label">Nombre Cliente:</b>
              {name}
            </div>
            <div>
              <b className="label">Identidad:</b>
              {id}
            </div>
          </div>
          <Affix className="row-wrapper" offsetTop={140}>
            <Row>
              <Col md={12} xs={18}>
                <Text strong> Total a pagar: </Text>
                <NumberFormat fixedDecimalScale decimalScale={2} value={totalAmount} displayType={'text'} thousandSeparator={true} prefix={'L. '} />
              </Col>
              <Col md={11} xs={6}>
                <Button type="primary" disabled={selectedRowKeys.length == 0}>
                  <Link to={{
                    pathname: "/card-payment",
                    state: { payments: selectedItems }
                  }}>
                    Continuar
                  </Link>
                </Button>
              </Col>
            </Row>
          </Affix>
          <Table
            loading={isLoading}
            rowKey={'id'}
            rowClassName={ (row:any, index: number) => DateTime.fromFormat(row.fecha, 'dd/MM/yyyy') < DateTime.fromJSDate(new Date()) ? 'expired' : '' }
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              hideSelectAll: true,
              getCheckboxProps: ((record: any) => ({
                disabled: !(minPayments[record.noOp] && (minPayments[record.noOp] == record.noCuota || minPayments[record.noOp] - 1 == record.noCuota ))
              }))
            }}
            dataSource={payments}
            columns={columns}
          />
        </div>
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

export default Payments;