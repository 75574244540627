import React, { useState } from 'react';
import logo from './../../img/logo.png';
import './header.scss';
import { Affix, Menu,  } from 'antd';
import { Link } from "react-router-dom";
import { UserStateContainer } from '../../container/UserStateContainer';

function Header(props: any) {
  const [current, setCurrent] = useState('Pagos Pendientes');
  const { setJwt, jwt } = UserStateContainer.useContainer();

  const handleClick = (props: any) => {
    setCurrent(props.key);

  };

  const signOut = () => {
    localStorage.removeItem('CREDILEE_JWT')
    setJwt('');
  }

  return (
    <Affix offsetTop={0}>
      <div style={{ zIndex: 1, width: '100%', top: 0, height: '140px', backgroundColor: 'white' }}>
        <div className="appHeader">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="title"> CrediLee - Más facilidades, <b>imposible</b> </h1>
        </div>
        {
          !props.hideMenu &&
          <div className="menu">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
              <Menu.Item key="Pagos Pendientes">
                <Link to="/payments">Pagos Pendientes</Link>
              </Menu.Item>
              <Menu.Item key="Cerrar sesión" style={{ float: 'right' }}>
                <Link to="/login" onClick={signOut}>Cerrar sesión</Link>
              </Menu.Item>
            </Menu>
          </div>
        }
      </div>
    </Affix>
  );
}

export default Header;