import { Spin } from "antd"
import React from "react";
import './loader.scss';

const Loader: React.FC<{
    show?: boolean
}> = ({ show }) => {
    if (!show) return null;
    return (
        <Spin
            className="custom-spiner" 
            size={"large"}
        />
    )
}

export default Loader