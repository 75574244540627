// import React from 'react';
import logo from '../../img/logo.png';
import './cardPayment.scss';
import { Form, Button, Input, Row, Col, notification, Descriptions, Modal, Steps, Drawer, Avatar, List, Typography, Timeline, Layout} from 'antd';
import axios from 'axios';
import { UserStateContainer } from '../../container/UserStateContainer';
import React, { useEffect, useState } from 'react';
import Header from '../../components/header/header';
import Card from 'react-credit-cards';
import { WarningOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import { Footer } from '../../components/footer/footer';
import { DateTime } from 'luxon';
const { Step } = Steps;
const { Text } = Typography;

const API_URL = `https://api.ladyleehn.com/credilee`;

let listening: any;

function CardPayment( props:any ) {
  const paymentDetails = props.location.state.payments;
  const DEFAULT_CC_MASK = "9999 9999 9999 9999";
  const { jwt, name, id } = UserStateContainer.useContainer();
  const history = useHistory();
  const [cardInfo, setCardInfo] = useState<{
    creditCardNumber: string;
    creditCardName: string;
    creditCardExpiry: string;
    creditCardCVC: string;
    creditCardIssuer: string;
  }>({ 
    creditCardNumber: '', 
    creditCardName: '', 
    creditCardExpiry:'', 
    creditCardCVC: '',
    creditCardIssuer: '',
  });
  const [ form ] = Form.useForm();
  const [ ccMask, setCCMask ] = useState<string>(DEFAULT_CC_MASK)
  const [ orderID, setOrderID ] = useState<string>();
  const [ isConfirmPayment, setIsConfirmPayment ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ viewDetail, setViewDetail ] = useState<boolean>(false);
  const [ currentFocus, setCurrentFocus ] = useState<"number" | "cvc" | "name" | "expiry">("number")

  useEffect(() => {
    if (jwt) {
      (window as any).JWT = jwt;
    }
  }, [jwt])


  const onChange = (values: any, allValues: any) => {
    if (values.creditCardNumber) {
      setCurrentFocus("number");
    } else if (values.creditCardName) {
      setCurrentFocus("name");
    } else if (values.creditCardCVC) {
      setCurrentFocus("cvc");
    } else if (values.creditCardExpiry) {
      setCurrentFocus("expiry");
    }

    let currentMask = DEFAULT_CC_MASK;
    if (allValues.creditCardNumber) {
      if (/^3[47]/.test(allValues.creditCardNumber)) {
        currentMask = '9999 999999 99999';
      }
    }
    setCCMask(currentMask);
    setCardInfo({
      ...cardInfo,
      ...allValues
    });
  }

  const checkPaymentStatus = () => {
    let orderId = orderID || (window as any).OrderID;
    axios.get(`${API_URL}/payment/${orderId}`, {
      headers: {
        Authorization: `Bearer ${(window as any).JWT || jwt}`
      }
    }).then(response => {
      setIsLoading(false);
      
      history.push('/payment-details', {
        paymentResponse: response.data,
        paymentDetails: paymentDetails
      })
    })
  }

  const doPayment = () => {
    setIsConfirmPayment(false);
    setIsLoading(true);
    axios.post(`${API_URL}/payment`, {
      identidad: "",
      detalleCuotas: paymentDetails,
      CardDetails: {
          CardNumber: cardInfo.creditCardNumber.replace(/\s|_/g,'').trim(),
          Type: cardInfo.creditCardIssuer,
          CardExpiryDate: cardInfo.creditCardExpiry.replace(/\/|_/g,''),
          CardCVV2: cardInfo.creditCardCVC.replace(/\s|_/g,'').trim(),
          CardHolderName: cardInfo.creditCardName
      }
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }).then(response => {
      (window as any).OrderID = response.data.payload.OrderID;
      setOrderID(response.data.payload.OrderID);
      // init loader
      if (response.data && response.data.payload && response.data.payload.HTMLFormData) {
        const winUrl = URL.createObjectURL(
          new Blob([response.data.payload.HTMLFormData], { type: "text/html" })
      );
        const wnd: Window | null = window.open(winUrl, '', 'width=500,height=700');
      } else {
        checkPaymentStatus();
      }
    }).catch(err => {
        setIsLoading(false);
        notification.error({
            message: 'Error al realizar pago',
            description: 'Valida los datos e intenta nuevamente',
        });
    })
  }

  const onFinish = () => {
    // message confirming action before send
    // let the user know this could take a while
    setIsConfirmPayment(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const creditCardCallback = (card: any, isValid: boolean) => {
    if (isValid) {
      setCardInfo({
        ...cardInfo,
        creditCardIssuer: card.issuer
      })
    }
  };

  const messageListenerCallback = (event : any) => {
    if (event && event.data && event.data === "post_payment") {
      checkPaymentStatus();
      window.removeEventListener("message", messageListenerCallback);
      listening = false;
    }
  }
  if (!listening){
    window.addEventListener("message", messageListenerCallback);
    listening = true;
  }

  const getTotal = () => {
    if (paymentDetails.length === 1) {
      return paymentDetails[0].saldoCuota;
    }
    const reducedValue = paymentDetails.reduce((accumulator:any, current:any) => {
      return (accumulator.saldoCuota ? accumulator.saldoCuota : accumulator) + current.saldoCuota
    })
    return reducedValue;
  }

  return (
    <Layout>
      <Layout.Content style={{ minHeight:'calc(101vh - 100px)' }}>
        <Header title="Pagos pendientes"></Header>
        <Loader show={isLoading} />
        <Row className="wrapper">
          <Col md={11} xs={24} >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onValuesChange={onChange}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Row>
                <Col md={24} xs={24}>
                  <Card
                    number={cardInfo.creditCardNumber ? cardInfo.creditCardNumber : ''}
                    name={cardInfo.creditCardName ? cardInfo.creditCardName : ''}
                    expiry={cardInfo.creditCardExpiry ? cardInfo.creditCardExpiry : ''}
                    cvc={cardInfo.creditCardCVC ? cardInfo.creditCardCVC : ''}
                    focused={currentFocus}
                    callback={creditCardCallback}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="creditCardNumber"
                    rules={[{ 
                      required: true, 
                      message: 'Ingrese número de tarjeta',
                    }]}
                  >
                    <InputMask 
                      type="tel"
                      className="ant-input"
                      mask={ccMask} 
                      placeholder="Número de Tarjeta de Credito"
                    />
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="creditCardName"
                    rules={[{ required: true, message: 'Ingrese su nombre' }]}
                  >
                    <Input 
                      placeholder="Nombre"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={11} xs={11}>
                  <Form.Item
                    name="creditCardExpiry"
                    rules={[
                      { required: true, message: 'Ingrese fecha de expiración' }, 
                      {
                        validator: (_, value) => 
                          DateTime.fromFormat(value, 'MM/yy') >= DateTime.fromJSDate(new Date()) ? Promise.resolve() : Promise.reject('Fecha inválida'),
                      }
                    ]}
                  >
                    <InputMask  
                      type="tel"
                      className="ant-input"
                      mask="99/99" 
                      placeholder="Expiración "  
                    />
                  </Form.Item>
                </Col>
                <Col md={1} xs={1}>
                  
                </Col>
                <Col md={12} xs={12}>
                  <Form.Item
                    name="creditCardCVC"
                    rules={[{ required: true, message: 'Ingrese codigo CVC' }]}
                  >
                    <InputMask 
                      type="tel"
                      className="ant-input"
                      mask="9999" 
                      maskPlaceholder={null}
                      placeholder="CVC"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                  <Col md={24} xs={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{width: '100%'}} loading={isLoading}>
                        Pagar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
            </Form>
          </Col>
          <Col md={2} xs={0} />
          <Col md={11} xs={24} className="detail-wrapper">
            <Descriptions title="Cuotas a pagar" bordered> 
              <Descriptions.Item label="Identidad" span={3}>{id}</Descriptions.Item>
              <Descriptions.Item label="Nombre" span={3}>{name}</Descriptions.Item>
              <Descriptions.Item label="Total a cancelar" span={3}>
                <NumberFormat fixedDecimalScale decimalScale={2} value={getTotal()} displayType={'text'} thousandSeparator={true} prefix={'L. '} />
              </Descriptions.Item>
            </Descriptions>
            <div className="detail-btn-wrapper">
              <Button onClick={() => setViewDetail(true)}> Ver Detalle </Button>
            </div>
          </Col>
        </Row>
        <Modal 
          title="Pago de Cuotas" 
          visible={isConfirmPayment} 
          onOk={doPayment} 
          onCancel={() => setIsConfirmPayment(false)} 
          cancelText="Cancelar"
        >
          <p>
            <WarningOutlined />
            {" "}
            Esta operación puede tardar un poco. Mientras el pago se esté procesando por favor no cierres esta ventana del navegador.
          </p>
          <p>
            Si su tarjeta es Visa o MasterCard, se levantará una ventana adicional del emisor bancario. Espere a que esta se cierre automáticamente
          </p>
        </Modal>
        <Drawer
          title="Detalles de Pago"
          placement="right"
          closable={true}
          onClose={() => setViewDetail(false)}
          visible={viewDetail}
        >
          <Timeline>
            {
              paymentDetails.map((item: any, index: number) => (
                <Timeline.Item key={`pd_${index}`}>
                  <Text strong>
                    Número de OP :
                  </Text>
                  <Text>
                    {` ${item.noOp}`}
                  </Text>
                  <br />
                  <Text strong>
                    Cuota : 
                  </Text>
                  <Text>
                    {` ${item.noCuota}`}
                  </Text>
                  <br />
                  <Text strong>
                    Saldo : 
                  </Text>
                  {' '}
                  <NumberFormat fixedDecimalScale decimalScale={2} value={item.saldoCuota} displayType={'text'} thousandSeparator={true} prefix={'L. '} />
                </Timeline.Item>
              ))
            }
          </Timeline>
        </Drawer>
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

export default CardPayment;