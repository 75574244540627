import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"

export interface UserState { 
    name: string,
    id: string,
    jwt: string,
    setJwt: (jwt: string) => void
    setName: (name: string) => void
    setId: (id: string) => void

}

export interface InitialUserState {
    jwt?: string
}

const useUserState = (initialState?: InitialUserState) => {
    const [jwt, setJwt] = useState<string>(initialState && initialState.jwt ? initialState.jwt : '');
    const [name, setName] = useState<string>('');
    const [id, setId] = useState<string>('');

    useEffect(() => {
        const storedToken = localStorage.getItem('CREDILEE_JWT');
        if (storedToken && !jwt){
            setJwt(storedToken);
        }
    }, []);
    return {
        jwt,
        name,
        id,
        setJwt,
        setName,
        setId,
    }
}

export const UserStateContainer = createContainer<UserState, InitialUserState>(useUserState);