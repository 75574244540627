// import React from 'react';
import logo from '../../img/logo.png';
import ayudaOP from '../../img/ayudaOP.png';
import frontPage from '../../img/frontPage.jpg';

import './login.scss';
import { Form, Button, notification, Popover, Layout } from 'antd';
import axios from 'axios';
import { UserStateContainer } from '../../container/UserStateContainer';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Footer } from '../../components/footer/footer';

const { Header, Content } = Layout;


function Login() {
    const { setJwt, jwt } = UserStateContainer.useContainer();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        if (jwt) {
            history.push('/payments');
        }
    }, [jwt]);

    const onFinish = (values: any) => {
        setIsLoading(true);
        axios.post('https://api.ladyleehn.com/credilee/login', {
            id: values.id.trim(),
            name: values.name.toUpperCase().trim(),
            op: parseInt(values.op.trim()),
        }).then(response => {
            setIsLoading(false);
            const data = response.data;
            if (data.success) {
                localStorage.setItem("CREDILEE_JWT", data.payload);
                setJwt(data.payload);
                // redirect will be handled on useEffect
            }
        }).catch(err => {
            notification.error({
                message: 'Error al iniciar sesion',
                description: 'Valida los datos e intenta nuevamenta',
            });
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log('Failure:', errorInfo);
    };

  return (
      <>
    <Layout className="login-wrapper">
        <Content>
            <div className="bigBox">
                <div className="content img">
                    <img className="login-img" alt="" src={frontPage}/>
                </div>
                <div className="content form">
                    <img src={logo} className="App-logo" alt="logo" />

                    <h1 className="login-label">
                        Iniciar Sesión**
                    </h1>
                    <Form name="login" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Form.Item
                            label="Nombre Completo"
                            name="name"
                            rules={[{ required: true, message: 'Ingresa tu nombre' }]}
                        >
                            <input />
                        </Form.Item>
                        <Form.Item
                            label="Identidad"
                            name="id"
                            rules={[{ required: true, message: 'Ingresa tu identidad' }]}
                        >
                            <input />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <span
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    >Número de operación a pagar</span>
                                    <Popover content={(
                                        <img src={ayudaOP}/>
                                    )} title="" placement="top">
                                        <InfoCircleOutlined />
                                    </Popover>
                                </>
                            }
                            name="op"
                            rules={[{ required: true, message: 'Ingresa tu número de operación a pagar:' }]}
                        >
                            <input />
                        </Form.Item>
                        <Form.Item>
                            <Button className="buttons" type="primary" htmlType="submit" loading={isLoading}>
                            Inicia Sesión
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Content>
        <Footer />
    </Layout>
    
    </>
  );
}

export default Login;