import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Routes from './container/Routes';


function App() {
  return (
    <div className="App">
      <Routes></Routes>
    </div>
  );
}

export default App;
