// import React from 'react';
import Header from '../../components/header/header';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { UserStateContainer } from '../../container/UserStateContainer';
import './paymentDetails.scss';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;

const PaymentDetails: React.FC<any> = ({ location }) => {
  const { setJwt, jwt, name, id, setName, setId } = UserStateContainer.useContainer();
  const paymentResponse = location.state.paymentResponse;
  const payloadResponse = paymentResponse.payload;
  const [ ladyLeeSuccess ] = useState<boolean>(payloadResponse && payloadResponse.codigoError && payloadResponse.codigoError === "00")
  // const [ facSuccess ] = useState<boolean>(payloadResponse && payloadResponse.ResponseCode && payloadResponse.ResponseCode === "1")
  const [ facSuccess ] = useState<boolean>(payloadResponse && payloadResponse.ResponseCode && payloadResponse.ResponseCode === "00")


  const  SuccessPayment = () => (
    <Result
        status="success"
        title="Pago Realizado con Exito!"
        subTitle={
            <Text strong style={{ color: "black" }}>
                Número de Orden: {payloadResponse && payloadResponse.OrderID} - Número de Referencia: {payloadResponse && payloadResponse.ReferenceNumber}
            </Text>
        }
        extra={[
        
        ]}
    >
        <Text
            style={{
            fontSize: 16,
            textAlign: "center"
        }}>
            Contactanos al departamento de cobros por whatsapp: 9511-7392, 9511-7654 (8:00 a.m a 5:00 p.m) o al correo electrónico cobroscredilee@ladylee.com
        </Text>
    </Result>
  )

  const LadyLeeError = () => (
    <Result
        status="error"
        title="Problema al procesar tu pago"
        subTitle={
            <Text strong style={{ color: "black" }}>
                Número de Orden: {payloadResponse && payloadResponse.OrderID} - Número de Referencia: {payloadResponse && payloadResponse.ReferenceNumber}
            </Text>
        }        
        extra={[
        
        ]}
    >
        <div className="desc">
            <Paragraph>
                <Text
                strong
                style={{
                    fontSize: 16,
                }}
                >
                Se efectuó el pago, pero hubo un problema aplicándolo a tu crédito. Por favor contacte a Administración CrediLee para resolver este problema. Una fotografía de esta pantalla seria de mucha ayuda.
                </Text>
                <br />
                <Text
                 style={{
                    fontSize: 16,
                    textAlign: "center"
                }}>
                    Contactanos al departamento de cobros por whatsapp: 9511-7392, 9511-7654 (8:00 a.m a 5:00 p.m) o al correo electrónico cobroscredilee@ladylee.com
                </Text>
            </Paragraph>
            <Text>
                Detalles Técnicos: 
            </Text>
            <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" /> 
                {" "}
                <Text strong>
                    CrediLee: 
                </Text>
                {" "}
                <Text>
                    { payloadResponse && payloadResponse.codigoMensaje }
                </Text>
            </Paragraph>
            <Paragraph>
                <CheckCircleOutlined />
                {" "}
                <Text strong>
                    Tarjeta de Crédito: 
                </Text>
                {" "}
                { payloadResponse && payloadResponse.ReasonCodeDesc }

            </Paragraph>
        </div>
    </Result>
  )

  const Error = () => (
    <Result
        status="error"
        title="Problema al procesar tu pago"
        subTitle={
            <Text strong style={{ color: "black" }}>
                Número de Orden: {payloadResponse && payloadResponse.OrderID} - Número de Referencia: {payloadResponse && payloadResponse.ReferenceNumber}
            </Text>
        }        
        extra={[
        
        ]}
    >
        <div className="desc">
            <Paragraph>
                <Text
                strong
                style={{
                    fontSize: 16,
                }}
                >
                No se realizo ningun cargo a tu tarjeta de credito. 
                Favor revisa tus datos e intentalo mas tarde
                </Text>
                <br />
                <Text
                 style={{
                    fontSize: 16,
                    textAlign: "center"
                }}>
                    Contactanos al departamento de cobros por whatsapp: 9511-7392, 9511-7654  (8:00 a.m a 5:00 p.m) o al correo electrónico cobroscredilee@ladylee.com
                </Text>
            </Paragraph>
            <Text>
                Detalles Tecnicos: 
            </Text>
            <Paragraph>
                <CloseCircleOutlined />
                {" "}
                { payloadResponse && payloadResponse.ReasonCodeDesc }
            </Paragraph>
        </div>
    </Result>
  )

  return (
    <div>
      <Header title="Resultado de Pago"></Header>
      {
          ladyLeeSuccess && facSuccess && <SuccessPayment />
      }
      {
          !ladyLeeSuccess && facSuccess && <LadyLeeError />
      }
      {
          !ladyLeeSuccess && !facSuccess && <Error />
      }
    </div>
  );
}

export default PaymentDetails;